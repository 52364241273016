/* eslint-disable react/no-unescaped-entities */

import React, { useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import MaterialTable, { MTableAction } from 'material-table';
import {
  Button,
  Grid,
  Paper,
  TextField,
  FormControl,
  Typography,
  Box,
} from '@material-ui/core';
import moment from 'moment';
import InfoWrapper from 'components/Applauz/InfoWrapper';
import UpdatePlanModal from './UpdatePlanModal';
import UpcomingPlan from './UpcomingPlan';
import { patchLicences } from 'helpers/api/subscriptions';

function redNegative(value) {
  return value < 0 ? { color: 'red' } : {};
}

export default function CurrentPlan({
  currentSubscription,
  currentPlan,
  upcomingPlan,
  subscriptionTypes,
  isLoading,
  setSelectedPrice,
  setDeletePriceModalIsOpen,
  setCustomPriceModalIsOpen,
  licencesRenewalData,
}) {
  const queryClient = useQueryClient();
  const [isOpen, setIsOpen] = useState(false);
  const [nextBillDate, setNextBillDate] = React.useState(null);
  const [deactivationDate, setDeactivationDate] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setNextBillDate(currentSubscription.dtNextBill?.substring(0, 10));
    setDeactivationDate(currentSubscription.dtDeactivated?.substring(0, 10));
  }, [currentSubscription]);

  const patchWithLoading = (params) => {
    setLoading(true);
    patchLicences({
      organizationId: currentPlan.organization_id,
      licenceTypeId: currentSubscription.typeId,
      ...params,
    })
      .then(() => {
        queryClient.invalidateQueries('account');
        queryClient.invalidateQueries('account.currentSubscription');
        setLoading(false);
      })
      .catch((patchLicencesError) => {
        alert(patchLicencesError);
        console.log({ patchLicencesError });
        setLoading(false);
      });
  };

  const setLicencesAsRenewed = () => {
    if (!currentSubscription.dtNextBill) {
      alert('Error: Next bill date not set!');
      return;
    }
    if (
      currentSubscription.dtLastBill
      && moment().isBefore(currentSubscription.dtLastBill)
    ) {
      alert('Error: Already set as renewed!');
      return;
    }
    if (currentSubscription.isFree) {
      alert('Error: Free plan!');
      return;
    }
    if (!currentSubscription.licenceLength || !currentSubscription.lengthUnit) {
      alert('Error: The length of the plan is unknown!');
      return;
    }
    setLoading(true);

    if (
      !confirm(
        `ATTENTION!\n
        This account won't receive an invoice at the end of the period, are you sure you want to proceed?
        You are setting the licences as already renewed, this operation cannot be reversed.
        This will affect the next and last billing dates.
        ARE YOU SURE?`
      )
    ) {
      return;
    }
    if (
      !confirm(
        `LAST CHANCE, Are you sure you want to set this account as renewed?`
      )
    ) {
      return;
    }
    patchLicences({
      organizationId: currentPlan.organization_id,
      licenceTypeId: currentSubscription.typeId,

      dtLastBill: currentSubscription.dtNextBill,
      dtNextBill: moment(currentSubscription.dtNextBill)
        .add(currentSubscription.licenceLength, currentSubscription.lengthUnit)
        .startOf('month'),
    })
      .then((response) => {
        console.log(response);
        queryClient.invalidateQueries('account');
        queryClient.invalidateQueries('account.currentSubscription');
        alert('Success');
        setLoading(false);
      })
      .catch((setLicencesAsRenewedError) => {
        console.log({ setLicencesAsRenewedError });
        alert(setLicencesAsRenewedError);
        setLoading(false);
      });
  };

  const hasUpcomingPlan = useMemo(
    () =>
      currentSubscription
      && upcomingPlan
      && (currentSubscription.total !== upcomingPlan.total
        || currentSubscription.pricePerLicence !== upcomingPlan.pricePerLicence
        || currentSubscription.frequency !== upcomingPlan.frequency),
    [upcomingPlan, currentSubscription]
  );

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper variant="outlined">
            <InfoWrapper
              label="Total licences"
              value={currentSubscription.total}
            />
            <InfoWrapper
              label="Licences used"
              value={currentSubscription.licencesUsed}
            />
            <InfoWrapper
              label="Plan"
              value={`${currentSubscription.frequency} - ${currentSubscription.frequencyDisplayText}`}
            />
            <InfoWrapper
              label="Price per licence"
              value={`$${currentSubscription.pricePerLicence?.toFixed(2)} ${
                currentSubscription.priceCurrency
              }`}
              valueStyle={redNegative(currentSubscription.pricePerLicence)}
            />
            <InfoWrapper
              label="Prorated Price"
              value={`${currentSubscription.proratedPrice?.toFixed(
                2
              )} (for the current date)`}
              valueStyle={redNegative(currentSubscription.proratedPrice)}
            />
            <InfoWrapper
              label="Prorated Credit"
              value={`${currentSubscription.proratedCredit?.toFixed(
                2
              )} (for the current date)`}
            />
            <InfoWrapper
              label="Plan start date"
              value={`${currentSubscription.startDate?.substring(0, 10)}`}
            />
            <InfoWrapper
              label="Plan end date"
              value={`${currentSubscription.endDate?.substring(0, 10)}`}
            />
            <InfoWrapper
              label="Number of days left"
              value={`${currentSubscription.numberOfDaysLeft}`}
              valueStyle={redNegative(currentSubscription.numberOfDaysLeft)}
            />
            <InfoWrapper
              label="Last bill date"
              value={`${currentSubscription.dtLastBill?.substring(0, 10)}`}
            />
            <InfoWrapper
              label="Next bill date"
              value={
                <FormControl fullWidth>
                  <TextField
                    id="date-start"
                    label=""
                    type="date"
                    variant="outlined"
                    value={nextBillDate}
                    onChange={(e) => setNextBillDate(e.target.value)}
                  />
                  <Button
                    onClick={() =>
                      patchWithLoading({ dtNextBill: nextBillDate })
                    }
                    color="primary"
                    variant="text"
                    disabled={loading}
                  >
                    Set Next bill date
                  </Button>
                </FormControl>
              }
            />
            {moment(nextBillDate).date() !== 1 && (
              <InfoWrapper
                label="Date warning"
                value={
                  <Typography color="error">
                    Don't use day other than the first if you don't know what
                    you're doing. The billing process will automatically cancel
                    the subscription if it fails to bill after the 6th day of
                    the month.
                  </Typography>
                }
              />
            )}
            <InfoWrapper
              label="Renewal"
              value={
                <FormControl fullWidth>
                  {licencesRenewalData ? (
                    `${licencesRenewalData.licencesCount} licences will be renewed on ${nextBillDate}. The price per licence will be $${licencesRenewalData.pricePerLicence} with a total of $${(licencesRenewalData.pricePerLicence * licencesRenewalData.licencesCount).toFixed(2)} + fees and taxes`
                  ) : (
                    <Typography color="error">
                      This account will not renew
                    </Typography>
                  )}
                  <Button
                    onClick={() => setLicencesAsRenewed()}
                    color="primary"
                    variant="text"
                    disabled={loading}
                  >
                    Set account as renewed
                  </Button>
                </FormControl>
              }
            />
            <InfoWrapper
              label="Deactivation date"
              value={
                <>
                  {deactivationDate ? deactivationDate : 'No deactivation date'}
                  {deactivationDate && (
                    <Button
                      color="primary"
                      variant="text"
                      onClick={() => {
                        patchWithLoading({
                          dtDeactivated: null,
                        });
                      }}
                    >
                      Undo
                    </Button>
                  )}
                </>
              }
            />
          </Paper>
        </Grid>
        {/** UPCOMING PLAN SECTION */}
        <Grid item xs={12}>
          <Paper variant="outlined">
            <Box p={2}>
              <Typography variant="h6">Change Plan</Typography>
            </Box>
            <UpcomingPlan
              upcomingPlan={upcomingPlan}
              showUpcomingPlanDetails={hasUpcomingPlan}
              onClickUpdatePlanButton={() => setIsOpen(true)}
              isLoading={isLoading}
              loading={loading}
            />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper variant="outlined">
            <MaterialTable
              title="Custom Prices"
              columns={[
                { title: 'Plan', field: 'type' },
                {
                  title: 'Start Date',
                  field: 'start',
                },
                { title: 'End Date', field: 'end' },
                { title: 'Price Per user', field: 'ppu' },
              ]}
              data={[...currentPlan.customPrices].map((i) => ({
                ...i,
                type:
                  subscriptionTypes.find((j) => i.type_id === j.value)?.name
                  || i.type,
              }))}
              options={{
                actionsColumnIndex: -1,
                paging: true,
                pageSize: 5,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [5],
                search: false,
              }}
              actions={[
                () => ({
                  icon: 'cancel',
                  iconProps: { style: { color: 'red' } },
                  tooltip: 'Delete Custom Price',
                  onClick: (_, { type_id, id }) => {
                    setSelectedPrice({ type_id, id });
                    setDeletePriceModalIsOpen(true);
                  },
                }),
                {
                  icon: 'add',
                  tooltip: 'Add new Custom Price',
                  isFreeAction: true,
                  onClick: () => setCustomPriceModalIsOpen(true),
                },
              ]}
              style={{ boxShadow: 'none' }}
              components={{
                // This is intended.
                // eslint-disable-next-line react/no-multi-comp
                Action: (props) => {
                  if (props.action.icon === 'add') {
                    return (
                      <Button
                        onClick={(event) =>
                          props.action.onClick(event, props.data)
                        }
                        color="primary"
                        variant="outlined"
                        style={{ textTransform: 'none' }}
                        size="small"
                      >
                        Add new Custom Price
                      </Button>
                    );
                  }
                  return <MTableAction {...props} />;
                },
              }}
            />
          </Paper>
        </Grid>
      </Grid>

      <UpdatePlanModal
        currentPlan={currentPlan}
        upcomingPlan={upcomingPlan}
        isOpen={isOpen}
        title={'Change Plan'}
        // actions
        onClose={() => setIsOpen(false)}
        onCancel={() => setIsOpen(false)}
        onClickCloseButton={() => setIsOpen(false)}
        onEndOfTermCancel={() => {
          patchWithLoading({
            dtDeactivated: moment(currentPlan.dt_next_bill)
              .startOf('day')
              .format('YYYY-MM-DD'),
          });
          setIsOpen(false);
        }}
        onImmediateCancel={() => {
          patchWithLoading({
            dtDeactivated: moment().format('YYYY-MM-DD'),
          });
          setIsOpen(false);
        }}
      />
    </>
  );
}
